import React from 'react'
import Footer from '../Footer';

import { teamMembers } from '../TeamMember'
import { Member } from './Member'

export default class TeamPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: teamMembers,
        per: 9,
        page: 1,
        total_pages: null,
    };
  }

  render(){
    return(
      <React.Fragment>
        <h3 className='ourteam-header'>Team Members</h3>
        <div className="ourteam-members"> 
          {
            teamMembers.map(( member, i )=><Member 
              key={i} 
              name={member.name} 
              image={member.image} 
              position={member.position}
              links ={member.links}
              desc={member.desc}
            />)
          }
          {/* <p className="ourteam-last-para">
            **The Preception team also includes many other talented individuals who have contributed, and continue to contribute in the following areas: Advisory/Mentorship, Hardware Development, Software Development, Database Management, Cloud Services, Business Strategy, Research, Customer Discovery/Acquisition, Fundraising, plus more.
          </p> */}
        </div>
        <Footer isHome={false}/>
      </React.Fragment>
    )
  }
}