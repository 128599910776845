import Benny from '../images/profile/Benny.jpg'
import yuan from '../images/profile/yuan-wang.png'
import Leigh from '../images/profile/Leigh.jpg'
import Andre from '../images/profile/Andre.jpg'
import Matt from '../images/profile/Matt.jpg'
import Jordan from '../images/profile/Jordan.jpg';
import Parul from '../images/profile/Parul.jpeg';

/**
 * This is where you can add or alter team members, the order of the members dictate their position
 * import image using a similar syntax above but change the name and the image location
 * copy and paste a single member and change relevant info
 */

export const teamMembers = [
  {
    name:"Leigh Sembaluk",
    position: "Founder, CEO",
    accreditation: "",
    image: Leigh,
    links: {
      linkedin: "https://www.linkedin.com/in/leighsembaluk/",
      medium: "https://medium.com/@sembaluk",
      amazon: "https://www.amazon.ca/Preception-Secret-Professional-High-Performance-Environments-ebook/dp/B0787LZML5"
    },
    desc: ["Former professional soccer player", "15+ years coaching experience in professional", "NCAA and elite organizations", "Author and consultant on human performance"]//"As a former professional soccer player, coach with the Vancouver Whitecaps and Simon Fraser University, Leigh has always been driven by testing the limits of human performance and an unwillingness to accept mediocrity.  As a best-selling author and methodology consultant on human performance, he became a tech founder to develop a technology to do what was thought impossible: to train and measure the ability to think and act before opponents.  His expertise in understanding how to build teams, and maximize and foster talent has led him to build and become part of this extraordinary group of individuals."
  },
  {
    name:"Andre Wirthmann",
    position: "Data Scientist/Sensors",
    accreditation: "PhD in Physics, MBA",
    image: Andre,
    links: {
    },
    desc: ["10+ years in Sensor platforms and algorithms", "PhD in Physics – University of Hamburg (Semiconductor Physics)", "MBA, Management of Technology (2012) – Simon Fraser University", "Previous Co-founder of IncuBone Laboratories"]
  },
  {
    name:"Matt Courtemanche",
    position: "Data Scientist",
    accreditation: "PhD Candidate in Physics",
    image: Matt,
    links: {
      linkedin: "https://www.linkedin.com/in/matthew-courtemanche-9338a6206"
    },
    desc: ["PhD in Physics - Simon Fraser University (completion Dec 2021)", "3+ years experience in data science", "Digital signal processing, statistical modelling and machine learning"]

  },
  {
    name:"Parul S.",
    position: "Product Manager",
    accreditation: "Business Analytics, Engineering BSc",
    image: Parul,
    links: {
      linkedin: "https://www.linkedin.com/in/parulsproduct"
    },
    desc: ["Experienced Product Manager/Product Owner", "Software Engineering background", "Expertise in Engineering and Business Analytics"]

  },
  {
    name:"Benny Lin",
    position: "Software Developer",
    accreditation: "BSc",
    image: Benny,
    links: {
      linkedin: "https://www.linkedin.com/in/benny-lin-94704886/"
    },
    desc: ["Full Stack Developer with focus in data visualizations", "BSc, Biology - Full Stack Developer with experience in bioinformatics"]
  },
  {
    name:"Jordan Shimell",
    position: "Research and Partnerships",
    accreditation: "PhD",
    image: Jordan,
    links: {
      linkedin: "https://www.linkedin.com/in/jordanshimell/"
    },
    desc: ["PhD in Neuroscience - University of British Columbia", "8+ years of entrepreneurial experience", "President of Neuroscience Graduate Student Association"]
  }
]

export const otherTeamMembers = [
  {
    name:"Yuan Wang",
    position: "Software Guru",
    image: yuan,
    links: {
      linkedin: "https://www.linkedin.com/in/theyuanwang/"
    },
    desc: "Taking more of an advisory role, Yuan boasts over two decades experience in the tech industry.  During this time he has built multiple startups, served as an executive at Blizzard Entertainment, Disney, and Midway Games and has spent the past few years focused on AI, data and decentralized technologies.  His passion for trans formative technologies and human performance drew him to Preception."

  }
]
