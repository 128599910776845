
import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import ContactButton from './Contact/Button';

const styles = {
  sidebar: {
    width: 256,
    height: "100%"
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "white",
    textDecoration: "none",
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "#0f2027"
  }
};

const SidebarContent = props => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  return (
      <div style={styles.content}>
        <a href="/" style={styles.sidebarLink} className="sidenav-title">
          Preception
        </a>
        <div style={styles.divider} />
        <div className='sidenav-links-item'>
            <Link to='/pod'>
            How It Works
            </Link>
        </div>
        <div className='sidenav-links-item'>
            <Link to='/teampage'>
            Our Team
            </Link>
        </div>
        <div className='sidenav-links-item'>
            <a href='https://www.proinstinctapp.com/' target="_blank">
            ProInstinct
            </a>
        </div>
        <div className='sidenav-links-item'>
            <ContactButton
                modalHandler={props.modalHandler}
                buttonName='navbar-links-button'
                buttonText='Contact Us'
            />
        </div>
      </div>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;