import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return(
                <div className="" key={this.props.data.key}>
                    <div className="card">
                        <div className="card-body">
                            <div className="avatar-mini">
                                <img
                                src={this.props.data.image}
                                className="card-img-top"
                                alt=""
                                />
                            </div>
                            <p className="card-title">
                                {this.props.data.name}
                            </p>
                            <p className="card-text team-section--members--position">
                                {this.props.data.position}
                            </p>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Card