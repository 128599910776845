import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo/white.png';
import menu from '../images/nav/menu.svg';
import exit from '../images/nav/exit.svg';

import ContactButton from './Contact/Button'

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    }
  }

  onClick = () => {
    this.setState({
      hidden: !this.state.hidden
    })
  }

  render() {
    let hiddenToggler = this.state.hidden ? 'navbar-links hidden' : 'navbar-links'

    return (
      <div className='not-nav'>
        <div className='navbar-toggler'>
          <img className='navbar-menu' src={menu} alt='hamburger menu' onClick={this.props.handleClick} />
        </div>

        <Link to='/'>
          <div className='navbar-brand'>
            <img
              className='navbar-brand-logo'
              src={logo}
              alt='navbar logo'
              onClick={this.closeMenu}
            />
            <h1 className='navbar-brand-title'>Preception</h1>
          </div>
        </Link>
        <div className="spacer"></div>
        <div className="navbar-links-group">
          <div className={hiddenToggler}>
            <div className='navbar-links-exit'>
              <img src={exit} alt='x' width='20px' onClick={this.closeMenu} />
            </div>

            <div className='navbar-links-item'>
              <Link to='/' onClick={this.closeMenu}>
                Home
              </Link>
            </div>
            <div className='navbar-links-item'>
              <Link to='/pod' onClick={this.closeMenu}>
                How It Works
              </Link>
            </div>
            <div className='navbar-links-item'>
              <Link to='/teampage' onClick={this.closeMenu}>
                Our Team
              </Link>
            </div>
            <div className='navbar-links-item'>
              <a href='https://www.proinstinctapp.com/' target="_blank" onClick={this.closeMenu}>
                ProInstinct
              </a>
            </div>
            <div className='navbar-links-item'>
              <ContactButton
                modalHandler={this.props.modalHandler}
                buttonName='navbar-links-button'
                buttonText='Contact Us'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
