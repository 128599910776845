import React from 'react'
// import Button from './Button'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import "./Item.css"

function Frame({title,desc,backgroundImg,leftBtnText,rightBtnText,twoButtons,descLink,leftBtnLink,rightBtnLink, first, child}) {
    return (
        <div className="frame" style={{
            backgroundImage: `url(${backgroundImg})`
        }}>
            <div className="frame-container">
                {child}
            </div>
        </div>
    )
}

export default Frame
