import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './Footer';
import Sidebar from 'react-sidebar';
import SidebarContent from "./sidebar_content";

import Home from './Home';
import Process from './Process';
import Pod from './Pod';
import DataMetric from './DataMetric';
import Modal from './Contact/Modal';
import TeamPage from './Team';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docked: false,
      open: false,
      transitions: true,
      touch: true,
      shadow: true,
      pullRight: false,
      touchHandleWidth: 20,
      dragToggleDistance: 30,
      display: false
    };

    this.onSetOpen = this.onSetOpen.bind(this);
    this.menuButtonClick = this.menuButtonClick.bind(this);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  menuButtonClick(ev) {
    ev.preventDefault();
    this.onSetOpen(!this.state.open);
  }

  closeHandler = () => {
    this.setState({ display: false })
  }

  modalHandler = () => {
    this.setState({
      display: !this.state.display
    })
  }

  render() {
    const sidebar = <SidebarContent modalHandler={this.modalHandler}/>;

    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      sidebarClassName: "custom-sidebar-class",
      contentId: "custom-sidebar-content-id",
      open: this.state.open,
      touch: this.state.touch,
      shadow: this.state.shadow,
      pullRight: this.state.pullRight,
      touchHandleWidth: this.state.touchHandleWidth,
      dragToggleDistance: this.state.dragToggleDistance,
      transitions: this.state.transitions,
      onSetOpen: this.onSetOpen
    };

    return (
      <Fragment>
        <Sidebar {...sidebarProps}>
            <div className="main">
              <Navbar modalHandler={this.modalHandler} handleClick={this.menuButtonClick} />
              <Modal display={this.state.display} closeHandler={this.closeHandler} />
              <Route
                exact
                path="/"
                render={props => <Home {...props} modalHandler={this.modalHandler} />}
              />
              {/* <Route path="/process" component={Process} /> */}
              <Route path="/pod" component={Pod} />
              {/* <Route path="/data" component={DataMetric} /> */}
              <Route path="/teampage" component={TeamPage} />
            </div>
        </Sidebar>
      </Fragment>
    )
  }
}
