import React, { Fragment } from 'react'

import Grid from '@material-ui/core/Grid';
import Card from './card';
import coolLogo from '../../images/logo/cool-logo.png'
import downArrow from '../../images/arrow.svg'
import stadium from '../../images/stadium.jpg';

import ContactButton from '../Contact/Button'
import Testimonials from './Testimonials'
import { teamMembers } from '../TeamMember'
import { Members } from './Members'
import { testimonials } from './testimonial_quotes'

import Frame from './frame.js';
import 'bootstrap/dist/css/bootstrap.css';
import Footer from '../Footer';


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: teamMembers,
        per: 9,
        page: 1,
        total_pages: null,
        inputValue: '',
        fieldActive: false,
        email: "",
    };
    this.updateInputValue = this.updateInputValue.bind(this);
    this.activateField = this.activateField.bind(this);
    this.disableFocus = this.disableFocus.bind(this);
  }


  handleArrowClick = () => {
  }

  // to activate the input field while typing
  activateField() {
    this.setState({
      fieldActive: true
    })
  }
  // to deactivate input only if it's empty
  disableFocus(e) {
    if (e.target.value === "") {
      this.setState({
          fieldActive: false
      })
    }
  }
  // to update the changes in the input and activate it
  updateInputValue(e) {
    this.setState({
      inputValue: e.target.value,
    });
    this.activateField(e);
    e.preventDefault();
  }

  render() {
    const members = teamMembers.map((member, i) => (
      <Members key={i} name={member.name} image={member.image} position={member.position} />
    ))
    const testimonial = testimonials.map(( elem, i )=><Testimonials key={i} quote={elem.quote} quoter={elem.quoter} job={elem.job} />)
    return (
      <Fragment>
        <div className="items-container">
          <div className="first-autoplay"></div>
          <div className="second-autoplay"></div>
          <Frame
            backgroundImg={stadium}
            child={<header className='header'>
            <img className='header--img' src={coolLogo} alt='preception logo' />
            <h1 className='header--primary-header'>Preception</h1>
            <h2 className='header--secondary-header'>The Future of Training</h2>
            <img
              className='header--arrow'
              onClick={this.handleArrowClick}
              src={downArrow}
              alt='scroll down'
            />
          </header>}
          >
          </Frame>
          <Frame
            child={<section className='quotes-section'>
            <div className='quotes-section-text'>
              <p className='quotes-section--primary-para'>
                We are a training tool for something that cannot be trained any other way...
              </p>
              <p className='quotes-section--secondary-para'>
                The ability to act first.
              </p>
            </div>
          </section>}
          >
          </Frame>
          <Frame 
            child={
              <section className='what-we-do-section'>
                  <h3 className='what-we-do-section--header no-img'>What We Do</h3>
                        <p className='what-we-do-section-para'>
                          Preception specifically trains and measures
                          <br />
                            <text className='what-we-do-section-para--bolded'>Spatial Awareness</text>,{' '}
                            <text className='what-we-do-section-para--bolded'>Speed of Thought</text>, and{' '}
                            <text className='what-we-do-section-para--bolded'>Decision Making</text>
                        </p>
                        <p className='what-we-do-section-para2'>
                          Our results in a total of 4 hours of training is <br />
                          a full step quicker in all directions
                        </p>

                        {/* <Link to="/process">
                        <button className="btn what-we-do-section--btn">Learn More</button>
                      </Link> */}
              </section>
            }
          >
          </Frame>
          <Frame 
            child={
              <section className='testinomal-section'>
                <h3 className='testinomal-section--header'>Testimonials</h3>
                {testimonial}
              </section>  
            }
          >
          </Frame>
          {/* <Frame
            child={
              <section className='how-it-works-section'>
              <h3 className='how-it-works-section--header'>How It Works</h3>
              <img className='how-it-works-section--img' src={pod} alt='preception on demand system' />
              <Link to='/pod'>
                <button className='btn how-it-works-section--btn'>Learn More</button>
              </Link>
            </section>
            }
          >
          </Frame> */}
          <Frame
            child={
              <section className='team-section'>
                <div className='team-section--header'>
                  <h3>Preception Team</h3>
                </div>

                {/* <div className='team-section--members'>{members}</div> */}
                <div className="flip-container-mini">
                  <Grid item xs={12}>
                        <Grid container justify="center" spacing="0">
                            {this.state.data.map(data => (
                              <Card data={data} />
                              ))}
                        </Grid>
                  </Grid>
                </div>
                <p className='team-section--quote'>
                  <em>"The strength of the team is each individual member. The strength of each member is the team."</em> <br />  Phil Jackson
                </p>
              </section>
            }
          >
          </Frame>
          <Frame
            child={
              <section className='launch-section'>
                
                <div className="launch-section--call">
                  <div className='launch-section--text'>
                    <p className='launch-section--primary-message'>
                      BETA LAUNCH 2022
                    </p>
                    <p className='launch-section--secondary-message'>
                      Keep updated on our world-class training and analytics platform that will give your team the ability to act first.
                    </p>
                  </div>

                  <div className="launch-section--email">
                    {/* <form id="" className="email-form">
                      <label
                      // check state the input, whether it is active then apply the class for floating label
                      className={this.state.fieldActive ? " field-active" : ""}
                      id="launch-label"
                      >
                        Email
                      </label>
                      <input
                        className="email-input"
                        type="text"
                        name="email"
                        value={this.state.inputValue}
                        onFocus={this.activateField}
                        onBlur={this.disableFocus}
                        onChange={this.updateInputValue}
                      />
                    </form> */}
                    <ContactButton
                      modalHandler={this.props.modalHandler}
                      buttonName='launch-button'
                      buttonText='Join List'
                    />
                  </div>
                </div>

                <div className='launch-section--pic'>
                </div>
                <Footer isHome={true}/>
              </section>
            }
          >
            
          </Frame>
        </div>
        

        

        {/* <section className="performance-metrics-section">
        <h3 className="performance-metrics-section--header">Preception Performance Metrics</h3>
        <ul className="performance-metrics-section--list">
          <li>Awareness Rating %</li>
          <li>Scans/Minute</li>
          <li>Average Decision Making Speed</li>
          <li>Complexity of Decisions</li>
          <li>Awareness Balance</li>
          <li>Orientation Rating</li>
        </ul>
        <Link to="/data">
          <button className="btn performance-metrics-section--btn">Learn More</button>
        </Link>
      </section> */}
        {/* <section className='roadmap-section'>
          <h1 className='roadmap-section--coming'>Product Launch</h1>
          <h1 className='roadmap-section--date'>2020</h1>

        </section> */}
      </Fragment>
    )
  }
}
